
export default {
  name: 'MediTabsItem',

  props: {
    name: {
      type: String,
      required: true,
    },

    isInitialActive: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isActive: this.isInitialActive,
    }
  },

  watch: {
    '$css.breakpoints.smAndDown' () {
      if (!this.$parent.tabsOnMobile) {
        if (this.$css.breakpoints.smAndDown) {
          this.isActive = false
          return
        }

        this.$parent.setActive(this.$parent.$children[0].$options.propsData.name)
        this.isActive = true
      }
    },

    isActive (val) {
      if (val && (this.$parent.tabsOnMobile ? false : this.$css.breakpoints.smAndDown)) {
        this.$nextTick(() => {
          this.$el.scrollIntoView({ behavior: 'smooth', inline: 'start' })
        })
      }
    },
  },

  methods: {
    setActive (active) {
      if (this.$css.breakpoints.smAndDown && !this.$parent.tabsOnMobile) {
        this.isActive = !this.isActive
        return
      }

      this.isActive = active
    },
  },
}
