

export default {
  name: 'MediTileSpecialization',
  props: {
    image: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    alt: {
      type: String,
      default: '',
    },

    url: {
      type: String,
      default: '',
    },
  },
}
